<template>
   <div class="SmallPupilOptometry">
     <p class="title">电脑验光</p>
     <div style="text-align: left;width: 100%;margin: auto;">
       <el-button @click="showset=true" style="margin-top: 20px;text-align: left;" type="primary">设置</el-button>
     </div>
     <div class="srA">
       <div>
         <ul class="content-sr padding-20">
           <li></li>
           <li>球镜</li>
           <li>柱镜</li>
           <li>轴向</li>
           <li>瞳距(mm)</li>
           <li></li>
         </ul>
         <ul class="content-sr">
           <li>OD</li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_DSOD, -30, 20)?'cw':'', srval==='S_OP_R_SC_DSOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_DSOD, 'S_OP_R_SC_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_R_SC_DSOD" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_DCOD, -10, 9)?'cw':'', srval==='S_OP_R_SC_DCOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_DCOD, 'S_OP_R_SC_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_R_SC_DCOD" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_XOD, 0, 180)?'cw':'', srval==='S_OP_R_SC_XOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_XOD, 'S_OP_R_SC_XOD', 0)" v-model="postData.S_OP_Json.S_OP_R_SC_XOD" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_PupilDistance_OD, 20, 100)?'cw':'', srval==='S_OP_R_SC_PupilDistance_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_PupilDistance_OD, 'S_OP_R_SC_PupilDistance_OD', 0)" v-model="postData.S_OP_Json.S_OP_R_SC_PupilDistance_OD" placeholder="请输入内容"></el-input></li>
           <li><el-button @click="tb(0)" class="last" style="margin: 0;" icon="el-icon-refresh" type="primary">同步数据</el-button></li>
         </ul>
         <ul class="content-sr padding-20">
           <li>OS</li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_DSOS, -30, 20)?'cw':'', srval==='S_OP_R_SC_DSOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_DSOS, 'S_OP_R_SC_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_R_SC_DSOS" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_DCOS, -10, 9)?'cw':'', srval==='S_OP_R_SC_DCOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_DCOS, 'S_OP_R_SC_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_R_SC_DCOS" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_XOS, 0, 180)?'cw':'', srval==='S_OP_R_SC_XOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_XOS, 'S_OP_R_SC_XOS', 0)" v-model="postData.S_OP_Json.S_OP_R_SC_XOS" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_PupilDistance_OS, 20, 100)?'cw':'', srval==='S_OP_R_SC_PupilDistance_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_PupilDistance_OS, 'S_OP_R_SC_PupilDistance_OS', 0)" v-model="postData.S_OP_Json.S_OP_R_SC_PupilDistance_OS" placeholder="请输入内容"></el-input></li>
           <li><el-button class="last" style="margin: 0; margin-left: 0.2rem;" type="primary" @click="showdrsb=true">导入设备数据</el-button></li>
         </ul>
       </div>
     </div>
     <div class="srA">
       <div>
         <ul class="content-sr padding-20">
           <li></li>
           <li>K1</li>
           <li></li>
           <li>K2</li>
           <li></li>
         </ul>
         <ul class="content-sr">
           <li>OD</li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_K1_A_OD, 0, 70)?'cw':'', srval==='S_OP_R_SC_K1_A_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_K1_A_OD, 'S_OP_R_SC_K1_A_OD', 1)" v-model="postData.S_OP_Json.S_OP_R_SC_K1_A_OD" placeholder="请输入内容"></el-input></li>
           <li>@<el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_K1_B_OD, 0, 180)?'cw':'', srval==='S_OP_R_SC_K1_B_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_K1_B_OD, 'S_OP_R_SC_K1_B_OD', 1)" v-model="postData.S_OP_Json.S_OP_R_SC_K1_B_OD" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_K2_A_OD, 0, 70)?'cw':'', srval==='S_OP_R_SC_K2_A_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_K2_A_OD, 'S_OP_R_SC_K2_A_OD', 0)" v-model="postData.S_OP_Json.S_OP_R_SC_K2_A_OD" placeholder="请输入内容"></el-input></li>
           <li>@<el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_K2_B_OD, 0, 180)?'cw':'', srval==='S_OP_R_SC_K2_B_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_K2_B_OD, 'S_OP_R_SC_K2_B_OD', 0)" v-model="postData.S_OP_Json.S_OP_R_SC_K2_B_OD" placeholder="请输入内容"></el-input></li>
         </ul>
         <ul class="content-sr padding-20">
           <li>OS</li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_K1_A_OS, 0, 70)?'cw':'', srval==='S_OP_R_SC_K1_A_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_K1_A_OS, 'S_OP_R_SC_K1_A_OS', 1)" v-model="postData.S_OP_Json.S_OP_R_SC_K1_A_OS" placeholder="请输入内容"></el-input></li>
           <li>@<el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_K1_B_OS, 0, 180)?'cw':'', srval==='S_OP_R_SC_K1_B_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_K1_B_OS, 'S_OP_R_SC_K1_B_OS', 1)" v-model="postData.S_OP_Json.S_OP_R_SC_K1_B_OS" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_K2_A_OS, 0, 70)?'cw':'', srval==='S_OP_R_SC_K2_A_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_K2_A_OS, 'S_OP_R_SC_K2_A_OS', 0)" v-model="postData.S_OP_Json.S_OP_R_SC_K2_A_OS" placeholder="请输入内容"></el-input></li>
           <li>@<el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SC_K2_B_OS, 0, 180)?'cw':'', srval==='S_OP_R_SC_K2_B_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SC_K2_B_OS, 'S_OP_R_SC_K2_B_OS', 0)" v-model="postData.S_OP_Json.S_OP_R_SC_K2_B_OS" placeholder="请输入内容"></el-input></li>
         </ul>
       </div>
     </div>
     <div style="text-align: left;padding-top: 20px;">
       <UPFILE :imgtype="'Refractive'" :wjtype="'电脑验光'" :datas.sync="contentData"></UPFILE>
<!--       <el-select class="xzlex"  v-model="valTypeidx" placeholder="请选择类型">-->
<!--         <el-option-->
<!--             v-for="item in options"-->
<!--             :key="item.value"-->
<!--             :label="item.label"-->
<!--             :value="item.value">-->
<!--         </el-option>-->
<!--       </el-select>-->
<!--       <el-button @click="dqwj" style="margin-left: 30px;" type="info">读取文件</el-button>-->
     </div>
     <div class="tp">


<!--       <UPfile :type="'RS'" :imglists="upimglist"></UPfile>-->

<!--       <el-upload-->
<!--           v-if="0"-->
<!--           action="#"-->
<!--           list-type="picture-card"-->
<!--           accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"-->
<!--           :file-list="fileLists"-->
<!--           ref="uptq"-->
<!--           :on-change="sc"-->
<!--           :auto-upload="false">-->
<!--         &lt;!&ndash;        <i slot="default" class="el-icon-plus"></i>&ndash;&gt;-->
<!--         <div>上传图片</div>-->
<!--         <div slot="file" slot-scope="{file}">-->
<!--           <img-->
<!--               class="el-upload-list__item-thumbnail"-->
<!--               :src="file.url" alt=""-->
<!--           >-->
<!--           <span class="el-upload-list__item-actions">-->
<!--        <span-->
<!--            class="el-upload-list__item-preview"-->
<!--            @click="handlePictureCardPreview(file)"-->
<!--        >-->
<!--          <i class="el-icon-zoom-in"></i>-->
<!--        </span>-->
<!--        <span-->
<!--            v-if="!disabled"-->
<!--            class="el-upload-list__item-delete"-->
<!--            @click="handleRemove(file)"-->
<!--        >-->
<!--          <i class="el-icon-delete"></i>-->
<!--        </span>-->
<!--      </span>-->
<!--         </div>-->
<!--       </el-upload>-->
<!--       <el-dialog :visible.sync="dialogVisible">-->
<!--         <img width="100%" :src="dialogImageUrl" alt="">-->
<!--       </el-dialog>-->
     </div>


     <div class="mar-20" v-if="qx.uf_r_sr && types">
       <p class="title">检影验光</p>
       <div class="srA">
         <div>
           <ul class="content-sr padding-20">
             <li></li>
             <li>球镜</li>
             <li>柱镜</li>
             <li>轴向</li>
             <li>视力</li>
           </ul>
           <ul class="content-sr">
             <li>OD</li>
             <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SR_DSOD, -30, 20)?'cw':'', srval==='S_OP_R_SR_DSOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SR_DSOD, 'S_OP_R_SR_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_R_SR_DSOD" placeholder="请输入内容"></el-input></li>
             <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SR_DCOD, -10, 9)?'cw':'', srval==='S_OP_R_SR_DCOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SR_DCOD, 'S_OP_R_SR_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_R_SR_DCOD" placeholder="请输入内容"></el-input></li>
             <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SR_XOD, 0, 180)?'cw':'', srval==='S_OP_R_SR_XOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SR_XOD, 'S_OP_R_SR_XOD')" v-model="postData.S_OP_Json.S_OP_R_SR_XOD" placeholder="请输入内容"></el-input></li>
             <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SR_VAOD, 0.01, 2)?'cw':'', srval==='S_OP_R_SR_VAOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SR_VAOD, 'S_OP_R_SR_VAOD')" v-model="postData.S_OP_Json.S_OP_R_SR_VAOD" placeholder="请输入内容"></el-input></li>
           </ul>
           <ul class="content-sr padding-20">
             <li>OS</li>
             <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SR_DSOS, -30, 20)?'cw':'', srval==='S_OP_R_SR_DSOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SR_DSOS, 'S_OP_R_SR_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_R_SR_DSOS" placeholder="请输入内容"></el-input></li>
             <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SR_DCOS, -10, 9)?'cw':'', srval==='S_OP_R_SR_DCOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SR_DCOS, 'S_OP_R_SR_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_R_SR_DCOS" placeholder="请输入内容"></el-input></li>
             <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SR_XOS, 0, 180)?'cw':'', srval==='S_OP_R_SR_XOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SR_XOS, 'S_OP_R_SR_XOS')" v-model="postData.S_OP_Json.S_OP_R_SR_XOS" placeholder="请输入内容"></el-input></li>
             <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SR_VAOS, 0.01, 2)?'cw':'', srval==='S_OP_R_SR_VAOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SR_VAOS, 'S_OP_R_SR_VAOS')" v-model="postData.S_OP_Json.S_OP_R_SR_VAOS" placeholder="请输入内容"></el-input></li>
           </ul>
         </div>
         <el-button @click="tb(1)" class="last" style="margin-left: 10px" icon="el-icon-refresh" type="primary">同步数据</el-button>
       </div>
     </div>

     <p v-if="qx.uf_r_ss && types" class="title mar-20">主观验光</p>
     <div v-if="qx.uf_r_ss && types" class="srA">
       <div>
         <ul class="content-sr w8 padding-20">
           <li></li>
           <li>球镜</li>
           <li>柱镜</li>
           <li>轴向</li>
           <li>远用矫正视力</li>
         </ul>
         <ul class="content-sr w8">
           <li>OD</li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_DSOD, -30, 20)?'cw':'', srval==='S_OP_R_SS_DSOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_DSOD, 'S_OP_R_SS_DSOD', 1)" v-model="postData.S_OP_Json.S_OP_R_SS_DSOD" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_DCOD, -10, 9)?'cw':'', srval==='S_OP_R_SS_DCOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_DCOD, 'S_OP_R_SS_DCOD', 1)" v-model="postData.S_OP_Json.S_OP_R_SS_DCOD" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_XOD, 0, 180)?'cw':'', srval==='S_OP_R_SS_XOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_XOD, 'S_OP_R_SS_XOD')" v-model="postData.S_OP_Json.S_OP_R_SS_XOD" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_DVAOD, 0.01, 2)?'cw':'', srval==='S_OP_R_SS_DVAOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_DVAOD, 'S_OP_R_SS_DVAOD')" v-model="postData.S_OP_Json.S_OP_R_SS_DVAOD" placeholder="请输入内容"></el-input></li>
         </ul>
         <ul class="content-sr w8 padding-20">
           <li>OS</li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_DSOS, -30, 20)?'cw':'', srval==='S_OP_R_SS_DSOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_DSOS, 'S_OP_R_SS_DSOS', 1)" v-model="postData.S_OP_Json.S_OP_R_SS_DSOS" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_DCOS, -10, 9)?'cw':'', srval==='S_OP_R_SS_DCOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_DCOS, 'S_OP_R_SS_DCOS', 1)" v-model="postData.S_OP_Json.S_OP_R_SS_DCOS" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_XOS, 0, 180)?'cw':'', srval==='S_OP_R_SS_XOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_XOS, 'S_OP_R_SS_XOS')" v-model="postData.S_OP_Json.S_OP_R_SS_XOS" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_DVAOS, 0.01, 2)?'cw':'', srval==='S_OP_R_SS_DVAOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_DVAOS, 'S_OP_R_SS_DVAOS')" v-model="postData.S_OP_Json.S_OP_R_SS_DVAOS" placeholder="请输入内容"></el-input></li>
         </ul>

         <ul class="content-sr w8 padding-20">
           <li></li>
           <li>ADD</li>
           <li>近用矫正视力</li>
           <li>瞳距</li>
           <li>近用瞳距</li>
         </ul>
         <ul class="content-sr w8">
           <li>OD</li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_AddOD, 0, 10)?'cw':'', srval==='S_OP_R_SS_AddOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_AddOD, 'S_OP_R_SS_AddOD', 1)" v-model="postData.S_OP_Json.S_OP_R_SS_AddOD" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_NVAOD, 0.01, 2)?'cw':'', srval==='S_OP_R_SS_NVAOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_NVAOD, 'S_OP_R_SS_NVAOD')" v-model="postData.S_OP_Json.S_OP_R_SS_NVAOD" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_PDOD, 20, 100)?'cw':'', srval==='S_OP_R_SS_PDOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_PDOD, 'S_OP_R_SS_PDOD')" v-model="postData.S_OP_Json.S_OP_R_SS_PDOD" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_NearPD_OD, 20, 100)?'cw':'', srval==='S_OP_R_NearPD_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_NearPD_OD, 'S_OP_R_NearPD_OD')" v-model="postData.S_OP_Json.S_OP_R_NearPD_OD" placeholder="请输入内容"></el-input></li>
         </ul>
         <ul class="content-sr w8 padding-20">
           <li>OS</li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_AddOS, 0, 10)?'cw':'', srval==='S_OP_R_SS_AddOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_AddOS, 'S_OP_R_SS_AddOS', 1)" v-model="postData.S_OP_Json.S_OP_R_SS_AddOS" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_NVAOS, 0.01, 2)?'cw':'', srval==='S_OP_R_SS_NVAOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_NVAOS, 'S_OP_R_SS_NVAOS')" v-model="postData.S_OP_Json.S_OP_R_SS_NVAOS" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_SS_PDOS, 20, 100)?'cw':'', srval==='S_OP_R_SS_PDOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_PDOS, 'S_OP_R_SS_PDOS')" v-model="postData.S_OP_Json.S_OP_R_SS_PDOS" placeholder="请输入内容"></el-input></li>
           <li><el-input @keydown.native="keyd($event)" :class="[srjc(postData.S_OP_Json.S_OP_R_NearPD_OS, 20, 100)?'cw':'', srval==='S_OP_R_NearPD_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_NearPD_OS, 'S_OP_R_NearPD_OS')" v-model="postData.S_OP_Json.S_OP_R_NearPD_OS" placeholder="请输入内容"></el-input></li>
         </ul>


         <ul class="content-sr padding-20;" style="padding-top: 2em;">
           <li>主导眼</li>
           <li>
             <el-select
                 class="srr"
                 v-model="postData.S_OP_Json.S_OP_R_SS_LeadingEye"
                 allow-create
                 collapse-tags
                 default-first-option
                 filterable
                 placeholder="请选择或输入">
               <el-option
                   v-for="item in zdy"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
             </el-select>
           </li>
           <li style="display: block; position: relative;">
             <p class="syph">双眼平衡</p>
             <el-select
                 class="srr"
                 v-model="postData.S_OP_Json.S_OP_R_SS_EyeBalanceTool"
                 allow-create
                 collapse-tags
                 default-first-option
                 placeholder="请选择或输入">
               <el-option
                   v-for="item in syphs"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
             </el-select>
           </li>
           <li><el-select
               class="srr"
               v-model="postData.S_OP_Json.S_OP_R_SS_EyeBalance"
               allow-create
               collapse-tags
               filterable
               default-first-option
               placeholder="请选择或输入">
             <el-option
                 v-for="item in syph"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select></li>
           <li @click="tb(2)" class="bntqz"><el-button icon="el-icon-refresh" type="primary">同步数据</el-button></li>
         </ul>
       </div>
     </div>

     <div class="mar-20" v-if="types">
       <el-collapse accordion>
         <el-collapse-item title="辅助检查" name="1">
           <div class="srA">
             <div>
               <ul class="content-sr padding-20">
                 <li></li>
                 <li>棱镜</li>
                 <li>基底</li>
                 <li></li>
                 <li>远</li>
                 <li>近</li>
               </ul>
               <ul class="content-sr">
                 <li>OD</li>
<!--                 <li><el-input @change="zh('S_OP_R_SS_PrismOD')" v-model="postData.S_OP_Json.S_OP_R_SS_PrismOD" placeholder="请输入内容"></el-input></li>-->
                 <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_R_SS_PrismOD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_PrismOD, 'S_OP_R_SS_PrismOD')" v-model="postData.S_OP_Json.S_OP_R_SS_PrismOD" placeholder="请输入内容"></el-input></li>
                 <li>
                   <el-select
                       class="srr"
                       v-model="postData.S_OP_Json.S_OP_R_SS_BaseOD"
                       filterable
                       allow-create
                       collapse-tags
                       default-first-option
                       placeholder="请选择或输入">
                     <el-option
                         v-for="item in jd"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                   </el-select>
                 </li>
                 <li>Worth4</li>
                 <li>
                   <el-select
                       class="srr"
                       v-model="postData.S_OP_Json.S_OP_R_SS_Worth4Far"
                       filterable
                       allow-create
                       collapse-tags
                       default-first-option
                       placeholder="请选择或输入">
                     <el-option
                         v-for="item in w4"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                   </el-select>
                 </li>
                 <li>
                   <el-select
                       class="srr"
                       v-model="postData.S_OP_Json.S_OP_R_SS_Worth4Near"
                       filterable
                       allow-create
                       collapse-tags
                       default-first-option
                       placeholder="请选择或输入">
                     <el-option
                         v-for="item in w4"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                   </el-select>
                 </li>
               </ul>
               <ul class="content-sr padding-20">
                 <li>OS</li>
<!--                 <li><el-input @change="zh('S_OP_R_SS_PrismOS')" v-model="postData.S_OP_Json.S_OP_R_SS_PrismOS" placeholder="请输入内容"></el-input></li>-->
                 <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_R_SS_PrismOS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_PrismOS, 'S_OP_R_SS_PrismOS')" v-model="postData.S_OP_Json.S_OP_R_SS_PrismOS" placeholder="请输入内容"></el-input></li>
                 <li>
                   <el-select
                       class="srr"
                       v-model="postData.S_OP_Json.S_OP_R_SS_BaseOS"
                       filterable
                       allow-create
                       collapse-tags
                       default-first-option
                       placeholder="请选择或输入">
                     <el-option
                         v-for="item in jd"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                   </el-select>
                 </li>
                 <li>立体视</li>
                 <li>
                   <el-select
                       class="srr"
                       v-model="postData.S_OP_Json.S_OP_R_SS_Stereopsis4Far"
                       filterable
                       allow-create
                       collapse-tags
                       default-first-option
                       placeholder="请选择或输入">
                     <el-option
                         v-for="item in lts"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                   </el-select>
                   "
                 </li>
                 <li>
                   <el-select
                       class="srr"
                       v-model="postData.S_OP_Json.S_OP_R_SS_Stereopsis4Near"
                       filterable
                       allow-create
                       collapse-tags
                       default-first-option
                       placeholder="请选择或输入">
                     <el-option
                         v-for="item in lts"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                   </el-select>
                   "
                 </li>
                 <li>
                   <el-select
                       class="srr"
                       v-model="postData.S_OP_Json.S_OP_R_SS_EyeBalanceTool"
                       allow-create
                       collapse-tags
                       default-first-option
                       placeholder="请选择或输入">
                     <el-option
                         v-for="item in lastsp"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                   </el-select>
                 </li>
               </ul>
               <ul class="content-sr padding-20">
                 <li>调节反应</li>
<!--                 <li><el-input @change="zh('S_OP_R_SS_BCC')" v-model="postData.S_OP_Json.S_OP_R_SS_BCC" placeholder="请输入内容"></el-input></li>-->
                 <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_R_SS_BCC'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_BCC, 'S_OP_R_SS_BCC')" v-model="postData.S_OP_Json.S_OP_R_SS_BCC" placeholder="请输入内容"></el-input></li>
                 <li>负相对调节</li>
<!--                 <li><el-input @change="zh('S_OP_R_SS_NRA')" v-model="postData.S_OP_Json.S_OP_R_SS_NRA" placeholder="请输入内容"></el-input></li>-->
                 <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_R_SS_NRA'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_NRA, 'S_OP_R_SS_NRA')" v-model="postData.S_OP_Json.S_OP_R_SS_NRA" placeholder="请输入内容"></el-input></li>
                 <li>正相对调节</li>
<!--                 <li><el-input @change="zh('S_OP_R_SS_PRA')" v-model="postData.S_OP_Json.S_OP_R_SS_PRA" placeholder="请输入内容"></el-input></li>-->
                 <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_R_SS_PRA'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_R_SS_PRA, 'S_OP_R_SS_PRA')" v-model="postData.S_OP_Json.S_OP_R_SS_PRA" placeholder="请输入内容"></el-input></li>
               </ul>
             </div>
           </div>
         </el-collapse-item>
       </el-collapse>
     </div>

     <div class="bottom" v-if="types">
       <p>备注</p>
       <el-input
           class="bzs"
           type="textarea"
           placeholder="请输入内容"
           v-model="postData.S_OP_Json.S_OP_R_SS_Remark"
           :autosize="{ minRows: 4, maxRows: 4 }"
           resize="none"
           show-word-limit
       >
       </el-input>
     </div>

     <el-dialog
         title=""
         :visible.sync="showdrsb"
         width="70%"
         >
       <ImportEquipment @getdata="drdata" :types="'oo'"></ImportEquipment>
       <span slot="footer" class="dialog-footer">
         <el-button @click="showdrsb = false">取 消</el-button>
       </span>
     </el-dialog>


     <el-dialog
         title="请选择设置项目"
         :visible.sync="showset"
         :close-on-click-modal="true"
         width="40%"
         :show-close="false"
         center>
       <div style="display: flex;justify-content: center;">
         <el-button type="primary" @click="setPz('setLY')">蓝牙设置</el-button>
         <el-button type="primary" @click="setPz('setIP')">IP设置</el-button>
       </div>
     </el-dialog>
     <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
   </div>
</template>

<script>
import pf from "@/publicFn/baseFn.js"
export default {
  name: "SmallPupilOptometry",
  data() {
    return {
      contentData: {},
      upimglist: [],
      qx: {},
      showset: false,
      showdrsb: false,
      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',
      iszf: 0,

      postData: {
        S_OP_ChooseId: "",
        S_OP_CustomerId: "",
        S_OP_ExpertId: "",
        S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
        S_OP_ID: "00000000-0000-0000-0000-000000000000",
        S_OP_Json: {
          S_OP_R_SC_DSOD: '',
          S_OP_R_ChooseId: '',
          S_OP_R_SC_PupilDistance_OD: '',
          S_OP_R_SC_PupilDistance_OS: '',
          S_OP_R_part1_isDone: '',
          S_OP_R_SC_DCOS: '',
          S_OP_R_SC_XOD: '',
          S_OP_R_SC_DCOD: '',
          S_OP_R_SC_DSOS: '',
          S_OP_R_SC_XOS: '',
          S_OP_R_SR_DSOS: '',
          S_OP_R_OR_VAOD: '',
          S_OP_R_SS_BaseOS: '',
          S_OP_R_SS_DVAOS: '',
          S_OP_R_OC_XOD: '',
          S_OP_R_OL_VAOD: '',
          S_OP_R_SS_LeadingEye: '',
          S_OP_R_SR_VAOS: '',
          S_OP_R_OC_PupilDistance_OS: '',
          S_OP_R_SR_XOD: '',
          S_OP_R_OR_DSOS: '',
          S_OP_R_OL_XOD: '',
          S_OP_R_SS_NVAOS: '',
          S_OP_R_OL_DSOS: '',
          S_OP_R_OC_PupilDistance_OD: '',
          S_OP_R_SS_XOS: '',
          S_OP_R_OR_VAOS: '',
          S_OP_R_OC_DCOD: '',
          S_OP_R_SS_BCC: '',
          S_OP_R_OL_VAOS: '',
          S_OP_R_SS_DCOD: '',
          S_OP_R_SS_AddOD: '',
          S_OP_R_SS_Stereopsis4Far: '',
          S_OP_R_SS_Stereopsis4Near: '',
          S_OP_R_SS_EyeBalanceTool: '',
          S_OP_R_SS_Worth4Near: '',
          S_OP_R_SS_Remark: '',
          S_OP_R_SS_Age: '',
          S_OP_R_SS_SVOD: '',
          S_OP_R_OC_santong: '',
          S_OP_R_SS_BaseOD: '',
          S_OP_R_SS_EyeBalance: '',
          S_OP_R_OC_DCOS: '',
          S_OP_R_SR_DCOD: '',
          S_OP_R_OC_DSOD: '',
          S_OP_R_SS_DCOS: '',
          S_OP_R_SS_XOD: '',
          S_OP_R_OR_XOS: '',
          S_OP_R_SS_PDOD: '',
          S_OP_R_SS_DSOD: '',
          S_OP_R_SS_NRA: '',
          S_OP_R_SS_SVOS: '',
          S_OP_R_OR_DCOD: '',
          S_OP_R_SS_AddOS: '',
          S_OP_R_OL_DCOD: '',
          S_OP_R_SS_DVAOD: '',
          S_OP_R_SS_PrismOD: '',
          S_OP_R_OC_XOS: '',
          S_OP_R_SR_DCOS: '',
          S_OP_R_part2_isDone: '',
          S_OP_R_OC_DSOS: '',
          S_OP_R_SR_DSOD: '',
          S_OP_R_SS_PrismOS: '',
          S_OP_R_SR_XOS: '',
          S_OP_R_SS_PRA: '',
          S_OP_R_SS_PDOS: '',
          S_OP_R_SS_NVAOD: '',
          S_OP_R_SS_DSOS: '',
          S_OP_R_OL_XOS: '',
          S_OP_R_SR_VAOD: '',
          S_OP_R_OR_DCOS: '',
          S_OP_R_OR_XOD: '',
          S_OP_R_OR_DSOD: '',
          S_OP_R_OL_DCOS: '',
          S_OP_R_O_Remark: '',
          S_OP_R_OL_DSOD: '',
          S_OP_R_SS_Worth4Far: '',
          S_OP_R_NearPD_OS: '',
          S_OP_R_NearPD_OD: '',
          S_OP_R_SC_K1_A_OD: '',
          S_OP_R_SC_K1_A_OS: '',
          S_OP_R_SC_K1_B_OD: '',
          S_OP_R_SC_K1_B_OS: '',
          S_OP_R_SC_K2_A_OD: '',
          S_OP_R_SC_K2_A_OS: '',
          S_OP_R_SC_K2_B_OD: '',
          S_OP_R_SC_K2_B_OS: ''
        },
        S_OP_Model: "S_OP_Refractive180726",
        S_OP_Time: "",
        S_OP_Type: "Refractive",
        S_OP_XML: ""
      },
      jd: [
        {
          value: '内',
          label: '内'
        },
        {
          value: '外',
          label: '外'
        },
        {
          value: '上',
          label: '上'
        },
        {
          value: '下',
          label: '下'
        }
      ],

      w4: [
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        },
        {
          value: '5',
          label: '5'
        },
        {
          value: '交替抑制',
          label: '交替抑制'
        }
      ],

      lts: [
        {
          value: '有',
          label: '有'
        },
        {
          value: '无',
          label: '无'
        },
        {
          value: '40',
          label: '40'
        },
        {
          value: '60',
          label: '60'
        },
        {
          value: '80',
          label: '80'
        },
        {
          value: '100',
          label: '100'
        },
        {
          value: '200',
          label: '200'
        },
        {
          value: '400',
          label: '400'
        },
        {
          value: '600',
          label: '600'
        },
        {
          value: '800',
          label: '800'
        }
      ],

      lastsp: [
        {
          value: '颜氏立体图',
          label: '颜氏立体图'
        },
        {
          value: 'Titmus',
          label: 'Titmus'
        }
      ],

      syphs: [
        {
          value: '偏光法',
          label: '偏光法'
        },
        {
          value: '棱镜法',
          label: '棱镜法'
        }
      ],

      syph: [
        {
          value: '右优',
          label: '右优'
        },
        {
          value: '左优',
          label: '左优'
        },
        {
          value: '双眼平衡',
          label: '双眼平衡'
        },
      ],
      syphv: '',
      syphvb: '',

      zdy: [
        {
          value: '右',
          label: '右'
        },
        {
          value: '左',
          label: '左'
        },
        {
          value: '交替',
          label: '交替'
        },
        {
          value: ' ',
          label: ' '
        }
      ],

      fileLists: [],
      dialogVisible: false,
      dialogImageUrl: '',
      valTypeidx: 1,
      options: [],
      isdrc: true
    }
  },
  props: {
    types: {
      default: 1
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upRefractive', n)
      },
      deep: true
    },
    contentData: {
      handler(n, o) {
        if (n) {
          // console.log(n)
          for (let key in n) {

            if (n[key]) {
              this.postData.S_OP_Json[key] = n[key]
            }
          }
          this.$store.commit('eyeTest/upRefractive', this.postData)
        }
      },
      deep: true
    }
  },
  created() {
    // console.log(this.$store.state.users.CsUser)
    this.qx = this.$store.state.users.jurisdiction
    if (!this.$store.state.users.CsUser.CustomerId) {return false}
    this._api.eyeTest.getRefractive()
        .then(res => {
          if (res.GetListResult && res.GetListResult.length > 0) {
            res.GetListResult[0].S_OP_Json = this._clJson(res.GetListResult[0].S_OP_Json)
            this.postData = res.GetListResult[0]
            // console.log(this.postData)
          }else {
            this.postData.S_OP_ChooseId = this.$store.state.physicianVisits.xzConsulting
            this.postData.S_OP_CustomerId = this.$store.state.users.CsUser.CustomerId
            this.postData.S_OP_Json.CL_RE_IP_ChooseId = this.$store.state.physicianVisits.xzConsulting
            this.postData.S_OP_Json.S_OP_R_ChooseId = this.$store.state.physicianVisits.xzConsulting
          }
        })

    //
    // this._api.publicApi.getImgList('Refractive')
    //     .then(res => {
    //       let arr = []
    //       if (res.GetListResult && res.GetListResult.length > 0) {
    //         for (let i = 0; i < res.GetListResult.length; i++) {
    //           arr.push({
    //             name: res.GetListResult[i].UFId,
    //             url: res.GetListResult[i].FullURL,
    //           })
    //         }
    //         this.fileLists = arr
    //       }
    //     })
//symbolize 象征性的,象征性的
    // this._api.publicApi.getOCRType('电脑验光')
    //     .then(res => {
    //       let arr = []
    //       this.baiduTo = res.BaiduToken
    //       if (res.GetListResult && res.GetListResult.length > 0) {
    //         this.oparr = JSON.parse(JSON.stringify(res.GetListResult))
    //         for (let i = 0; i < res.GetListResult.length; i++) {
    //           arr.push({
    //             value: i + 1,
    //             label: res.GetListResult[i].BDOCR_TempName
    //           })
    //         }
    //       }
    //       this.options = arr
    //     })

    document.addEventListener('click', this.getXY, false)
  },
  methods: {
    tb(idx) {
      let datas = this.$store.state.eyeTest.refractiveData.S_OP_Json
      switch (idx) {
        case 0:
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SC_DSOD', datas.S_OP_R_OC_DSOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SC_DCOD', datas.S_OP_R_OC_DCOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SC_XOD', datas.S_OP_R_OC_XOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SC_PupilDistance_OD', datas.S_OP_R_OC_PupilDistance_OD)

          this.$set(this.postData.S_OP_Json, 'S_OP_R_SC_DSOS', datas.S_OP_R_OC_DSOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SC_DCOS', datas.S_OP_R_OC_DCOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SC_XOS', datas.S_OP_R_OC_XOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SC_PupilDistance_OS', datas.S_OP_R_OC_PupilDistance_OS)
          break
        case 1:
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SR_DSOD', this.postData.S_OP_Json.S_OP_R_SC_DSOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SR_DCOD', this.postData.S_OP_Json.S_OP_R_SC_DCOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SR_XOD', this.postData.S_OP_Json.S_OP_R_SC_XOD)

          this.$set(this.postData.S_OP_Json, 'S_OP_R_SR_DSOS', this.postData.S_OP_Json.S_OP_R_SC_DSOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SR_DCOS', this.postData.S_OP_Json.S_OP_R_SC_DCOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SR_XOS', this.postData.S_OP_Json.S_OP_R_SC_XOS)
          break
        case 2:
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SS_DSOD', this.postData.S_OP_Json.S_OP_R_SC_DSOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SS_DCOD', this.postData.S_OP_Json.S_OP_R_SC_DCOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SS_XOD', this.postData.S_OP_Json.S_OP_R_SC_XOD)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SS_PDOD', this.postData.S_OP_Json.S_OP_R_SC_PupilDistance_OD)

          this.$set(this.postData.S_OP_Json, 'S_OP_R_SS_DSOS', this.postData.S_OP_Json.S_OP_R_SC_DSOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SS_DCOS', this.postData.S_OP_Json.S_OP_R_SC_DCOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SS_XOS', this.postData.S_OP_Json.S_OP_R_SC_XOS)
          this.$set(this.postData.S_OP_Json, 'S_OP_R_SS_PDOS', this.postData.S_OP_Json.S_OP_R_SC_PupilDistance_OS)
      }
    },

    drdata(data) {
      if (data) {
        // console.log(data.RightSPH?(data.RightSPH).toString():'')
        this.postData.S_OP_Json.S_OP_R_SC_DSOD = data.RightSPH?(data.RightSPH).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_DCOD = data.RightCYL?(data.RightCYL).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_XOD = data.RightAxis?(data.RightAxis).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_PupilDistance_OD = (data.PD/2)?(data.PD/2).toString():''

        this.postData.S_OP_Json.S_OP_R_SC_DSOS = data.LeftSPH?(data.LeftSPH).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_DCOS = data.LeftSPH?(data.LeftCYL).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_XOS = data.LeftAxis?(data.LeftAxis).toString():''
        this.postData.S_OP_Json.S_OP_R_SC_PupilDistance_OS = (data.PD/2)?(data.PD/2).toString():''

        this.showdrsb = false
      }
    },

    zh(key,type=0) {
      if (type) {
        if (Number(this.postData.S_OP_Json[key]) > 0 && this.postData.S_OP_Json[key].toString().indexOf('+')==-1) {
          this.postData.S_OP_Json[key] = "+" + this.postData.S_OP_Json[key]
        }
      }
      this.postData.S_OP_Json[key] = this.postData.S_OP_Json[key] !== ''?this.postData.S_OP_Json[key].toString():''
    },

    handleRemove(file) {
      this._api.publicApi.deleImg(file.name)
          .then(res => {
            if (res.DeleteByIdReturn) {
              let upwj = this.$refs.uptq.uploadFiles
              for (let i = 0; i < upwj.length; i++) {
                if (upwj[i]['url'] == file.url) {
                  upwj.splice(i, 1)
                }
              }
            }
          })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      //console.log(file);
    },

    sc(file, f) {
      let _this = this
      let reader = new FileReader(), imgBase64 = '';
      reader.readAsDataURL(file.raw)
      reader.onload = function (e) {
        _this._http.post(pf.getUrl('UploadFile_Upload'), {
          "token": {
            "TimeSpan": 1566827733,
            "sToken": "768DCAFFF869CD61372790C2738012EC"
          },
          o: {
            FileBase64: e.currentTarget.result.split(',')[1],
            ExtensionName: ".png",
            UFSaveType: 'OSSPrivate',
            UFReObject: "Choose",
            UFType: 'CTM',
            UFReId: _this.$store.state.physicianVisits.xzConsulting
          }
        })
            .then(res => {
              if (res.Id) {
                file.name = res.Id
              }
            })
      }
    },

    convertBase64UrlToBlob(urlData){
      let arr = urlData.split(','),
          bstr = window.atob(urlData), n = bstr.length, u8arr = new window.Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new window.Blob([u8arr], {type:'png'});
    },

    wjsc() {
      let _this = this
      window.getReportResult = function (res) {
        // let upwj = _this.$refs.uptq.uploadFiles
        // let obj = JSON.parse(JSON.stringify(_this.postData))
        //console.log(res)
        _this.isdrc = true
        if (res.BaiduResult) {
          let datas = JSON.parse(res.BaiduResult).data
          if (datas.ret) {
            let item = {},obj = JSON.parse(JSON.stringify(_this.postData))
            for (let i = 0; i < datas.ret.length; i++) {
              let str = datas.ret[i].word?datas.ret[i].word.toString():''
              switch (datas.ret[i].word_name) {
                case 'S_OP_R_SC_DSOD':
                  obj.S_OP_R_SC_DSOD = str
                  break
                case 'S_OP_R_SC_DSOS':
                  obj.S_OP_R_SC_DSOS = str
                  break
                case 'S_OP_R_SC_DCOD':
                  obj.S_OP_R_SC_DCOD = str
                  break
                case 'S_OP_R_SC_DCOS':
                  obj.S_OP_R_SC_DCOS = str
                  break
                case 'S_OP_R_SC_XOD':
                  obj.S_OP_R_SC_XOD = str
                  break
                case 'S_OP_R_SC_XOS':
                  obj.S_OP_R_SC_XOS = str
                  break
                case 'S_OP_R_SC_PupilDistance_OD':
                  obj.S_OP_R_SC_PupilDistance_OD = str
                  break
                case 'S_OP_R_SC_PupilDistance_OS':
                  obj.S_OP_R_SC_PupilDistance_OS = str
                  break
              }
            }
            _this.postData.S_OP_Json = obj
            // _this._api.publicApi.OCRsecond(_this.kfid, true)
            // .then(res => {
            //   console.log(res)
            // })
          }
        }
        if (res.ReportImages && res.ReportImages.length) {
          for (let i = 0; i < res.ReportImages.length; i++) {
            _this._api.publicApi.upFiles(res.ReportPages[i].ImageBase64, 'RS')
                .then(res => {
                  _this.upimglist.push(res)
                })
          }
        }
        if (res.ReportPages && res.ReportPages.length) {
          for (let i = 0; i < res.ReportPages.length; i++) {
            _this._api.publicApi.upFiles(res.ReportPages[i].PageBase64, 'RS')
            .then(res => {
              _this.upimglist.push(res)
            })
          }
        }
      }
    },

    dqwj() {
      if (!this.$store.state.users.employees) {
        return this.$alert('员工未登录请登录！', '提示')
            .then(res => {this.$router.push({path: '/employeesLanding'})})
      }
      if (!this.valTypeidx) {
        return this.$alert('请选择类型！', '提示')
      }

      if (!this.isdrc) return

      this.isdrc = false

      this.wjsc()

      let str = this.$store.state.physicianVisits.xzConsulting.slice(0,8).toUpperCase()
      let name = this.$store.state.users.CsUser.CustomerName,
          ys = this.$store.state.users.employees.ExpertName,
          temid = this.oparr[this.valTypeidx-1].BDOCR_TempId
      let obj = {
        ChooseNo: str,
        Expert: ys,
        Customer: name,
        CheckType: "电脑验光",
        TempId: temid,
        BDToken: this.baiduTo,
        PageSite: this.oparr[this.valTypeidx-1].BDOCR_PageSite,
        ImageSite: this.oparr[this.valTypeidx-1].BDOCR_ImageSite,
        "OcrId": this.oparr[this.valTypeidx-1].BDOCR_Id,
        "TempName": this.oparr[this.valTypeidx-1].BDOCR_BrandName,
        "CompanyId": this.$store.state.users.user.Company.CompanyId,
        "ShopId": this.$store.state.bases.shop.ShopId,
        "ChooseId": this.$store.state.physicianVisits.xzConsulting,
        "HealthCheckId":'',
        "CustomerId": this.$store.state.users.CsUser.CustomerId,
        "ExpertId":  this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:"00000000-0000-0000-0000-000000000000"
      }

      window.bound.selReport(JSON.stringify(obj));

      /*this._api.publicApi.OCRLfirst(this.oparr[this.valTypeidx-1].BDOCR_BrandName, this.oparr[this.valTypeidx-1].BDOCR_Id)
          .then(res => {
            // console.log(res)
            if (res.ReturnCode == 5000) {
              this.kfid = res.Result
              window.bound.selReport(JSON.stringify(obj));
            }else {
              this.$alert(res.displaymsg, '提示')
            }
          })*/

    },

    keyd(e) {
      // e.returnValue= false
      if (pf.isMobile()) {
        e.returnValue= false
      }
      // console.log(e)
    },

    srjc(val, min, max) {
      if (val) {
        if (val.indexOf('^') > -1) return false
        if (isNaN(Number(val))) return true
        if (Number(val) > Number(max)) return true
        if (Number(val) < Number(min)) return true
        return false
      }
      return false
    },

    getgb(str, key, iszf) {
      if (pf.isMobile()) {
        document.activeElement.blur();
      }


      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.postData.S_OP_Json, this.srval, e)
      if (this.postData.S_OP_Json[this.srval] && !isNaN(Number(this.postData.S_OP_Json[this.srval])) && this.iszf) {
        if (this.postData.S_OP_Json[this.srval] && Number(this.postData.S_OP_Json[this.srval]) > 0 && this.postData.S_OP_Json[this.srval].indexOf('+') < 0  && this.postData.S_OP_Json[this.srval].indexOf('-') < 0) {
          this.$set(this.postData.S_OP_Json, this.srval, '+'+e)
        }
      }
    },


    setPz(key) {
      if (window.androids) {
        window.androids[key]()
      }
    },
  },


  beforeDestroy() {
    document.removeEventListener('click', this.getXY)
  },

  components: {
    ImportEquipment: () => import('@/components/specialSurveys/refractive/importEquipment'),
    KEYS: () => import('@/components/keyboard/keyHome'),
    UPfile: () => import('@/components/CommonComponents/upFile'),
    UPFILE: () => import('../../sbData')
  }
}
</script>

<style scoped lang="scss">
  .tp {
    display: flex;
    justify-content: start;
    padding-top: 20px;
  }
  .SmallPupilOptometry {
    width: 90%;
    margin: 0 auto;
    overflow-y: auto;
    padding-top: 0.4rem;
  }
  ::v-deep .SmallPupilOptometry  .el-collapse-item__header {font-size: 18px}
  .mar-20 {margin-top: 30px}
  ::v-deep .refraction  .el-collapse-item__header {font-size: 18px}
  .bntqz  .el-button {
    padding: 0;
    width: 100%;
    height: 32px;
  }
  .title {
    font-size: 18px;
    text-align: left;
  }
  .padding-20{padding-top: 15px;}
  .srA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 8vw;
        margin-right: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
        .syph {
          position: absolute;
          top: -1.4em;
          text-align: center;
          width: 100%;
        }
      }
      li:nth-child(1) {
        width: 4vw;
        justify-content: start;
        margin-right: 5px;
      }
    }
    .w8 { li {width: 7vw;} }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-left: 50px;
      margin-top: 32px;
    }
  }

  ::v-deep .srA .content-sr input:hover {background: #e8e6e6;}

  .lastxz  .l-zs{
    border: none;
    border-bottom: 1px solid #000000;
    border-radius: 0;
  }
  .bottom {
    margin: 0 auto;
    display: flex;
    justify-content: start;
    margin-top: 20px;
    .bzs {
      width: 95%;
    }
    p {width: 5%;text-align: left}
  }
  .cw {background: #ef4674;}
  ::v-deep .cw  .el-input__inner{background: #ef4674 !important;}
  //.xz {background: #8f8e8e}
  ::v-deep .xz input{background: #e8e6e6}
</style>
